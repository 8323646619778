/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontFamily: `Kanit`,
    fontSize: '25px',
    fontWeight: '800',
    color: '#444444',
    textTransform: 'uppercase',
    marginBottom: '0px',
    marginTop: '0px'
  },
  underLine: {
    borderBottom:'1px solid #444',
    width:'6%',
    margin:'5px auto',
    display:'block'
  }
}));

function Section({ children }) {
  const classes = useStyles();
  return (
    <main>
        <h3 md="1" className={classes.title}>
          {children}
        </h3>
        <div className={classes.underLine}></div>
    </main>
  )
}
Section.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Section

