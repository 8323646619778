import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

function Badge({ ...props }) {
  const { classes, color ,  children } = props;
  return (
    <span className={classes.badge + " " + classes[color]}>{children}</span>
  );
}

Badge.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "yellow",
    "white",
    "blue"
  ]),
  children: PropTypes.node
};
const style= {
  badge: {
    fontFamily: `Kanit`,
    borderRadius: "12px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "1",
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block",
    marginRight: '5px',
    marginBottom: '5px'
  },
  yellow: {
    color: '#444444',
    backgroundColor: "#ffc107",
  },
  white: {
    color: '#444444',
    backgroundColor: "#ffffff",
  },
  blue: {
    color: '#ffffff',
    backgroundColor: "#1ba0e2",
  },
}

export default withStyles(style)(Badge);
